// src/components/HomePage.js

import React from 'react';
import logo from './images/web_logo.png';
import bear from './images/anxiousBear.png';

const businessEmail = "gerrydoesthat@gmail.com";
const emailSubject = "I feel I need to email you for some reason";

const HomePage = () => {
  return (
    <div>
      <header className='sticky-top'>
        {/* Navbar */}
        <nav className="navbar">
          <a className="navbar-brand" href="#home">
            <img
              src={logo}
              alt="Mozart Logo"
              width="150"
              height="auto"
              className="d-inline-block align-top"
            />
          </a>
          <div className="mx-auto text-center">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#wheretheshirtsare">
                  Where There Are Shirts 🦉
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#wheretheshirtsarenot">
                  Where There Are NO Shirts
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <section className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light" id="home">
        <h1>Bear with me, while I find a better print service</h1>
        <img
            src={bear}
            alt="RAWR"
            width="900"
            height="600"
            className="img-fluid"
        />
      </section>

      {/* Foot, loose */}
      <footer>
        <p>&copy; 2024 Moz'Art shirts 'n stuff. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
